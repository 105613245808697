export const tituloPrincipal = {
  titulo: 'ALUMBRADO PUBLICO DE VILLA NUEVA, S.A',
  style: {
    font: {
      bold: true,
      size: 20,
      color: '#ffffff',
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: '#0070c0',
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true,
      width: 15,
    },
  },
}

export const tituloSecundario = {
  titulo: 'BRECHA DE ILUMINACIÓN',
  style: {
    font: {
      bold: true,
      size: 20,
      color: '#ffffff',
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: '#0070c0',
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true,
    },
  },
}

export const subtitulo = {
  subtitulo: 'REPORTE GENERAL DE BRECHA DE ILUMINACIÓN',
  style: {
    font: {
      bold: true,
      size: 10,
      color: '#ffffff',
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: '#0070c0',
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true,
    },
  },
}

export const tituloDescarga = 'REPORTE DE BRECHA DE ILUMINACIÓN'

function getTrabajosRealizados(trabajosRealizados) {
  return trabajosRealizados.map(trabajo => trabajo.nombre).join(', ')
}
export function getColumns() {
  return [
    {
      label: 'Diurno / Nocturno',
      width: 20,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Poste',
      width: 15,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Brigada',
      width: 20,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Fecha Creación',
      width: 15,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Coordenadas',
      width: 30,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Trabajo(s) Realizado(s)',
      width: 50,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Metros de Brecha',
      width: 25,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Brazo Retirado',
      width: 25,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Brazo Nuevo',
      width: 25,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Código de Lámpara Retirada',
      width: 30,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Potencia de Lámpara Retirada',
      width: 30,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Código de Lámpara Instalada',
      width: 30,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
    {
      label: 'Potencia de Lámpara Instalada',
      width: 30,
      style: {
        font: {
          bold: true,
          size: 12,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      },
    },
  ]
}

export function getRows(rows) {
  const listado = []

  rows.forEach(row => {
    const trabajosRealizados = row.trabajosRealizados || []
    const item = {
      diurnoNocturno: {
        value: row.tipoFalla.nombre,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        }, // style que va tener la fila en el reporte
      },
      poste: {
        value: row.poste,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      brigada: {
        value: row.brigada.nombre,
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      fechaCreacion: {
        value: row.fechaCreacion,
        type: 'date',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      coordenadasBrecha: {
        value: (row.seguimiento && row.seguimiento.length > 0 && row.seguimiento[0].localization) ? `${row.seguimiento[0].localization.latitude}, ${row.seguimiento[0].localization.longitude}` : '',
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      trabajosBrecha: {
        value: getTrabajosRealizados(trabajosRealizados),
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      brecha: {
        // eslint-disable-next-line no-shadow
        value: row.trabajosRealizados.find(item => item.id === 1)?.trabajo || '',
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      brazoRetirado: {
        // eslint-disable-next-line no-shadow
        value: row.trabajosRealizados.find(item => item.id === 2)?.trabajo?.brazoRetirado?.nombre || '',
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      brazoNuevo: {
        value: row.trabajosRealizados.find(item => item.id === 2)?.trabajo?.brazoInstalado?.nombre || '',
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      codigoLamparaRetirada: {
        value: row.trabajosRealizados.find(item => item.id === 3)?.trabajo?.codigoAnterior || '',
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      potenciaLamparaRetirada: {
        value: row.trabajosRealizados.find(item => item.id === 3)?.trabajo?.potenciaAnterior || '',
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      codigoLamparaInstalada: {
        value: row.trabajosRealizados.find(item => item.id === 3)?.trabajo?.codigoNuevo || '',
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
      potenciaLamparaInstalada: {
        value: row.trabajosRealizados.find(item => item.id === 3)?.trabajo?.potenciaNueva || '',
        type: 'string',
        style: {
          font: {
            size: 10,
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center',
            wrapText: true,
          },
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        },
      },
    }
    listado.push(item)
  })

  return listado
}
